<template>
  <div>
    <b-overlay
      :show="loading"
      rounded="sm"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
    >
      <div class="flex">
        <div style="flex: 1">
          <b-row class="align-items-center">
            <b-col cols="12" :md="12">
              <b-row no-gutters>
                <b-col cols="12" :md="4">
                  <div
                    class="position-relative bg-white border rounded text-center p-25"
                  >
                    <b-img
                      v-if="orderDetail.order_item"
                      style="max-height: 120px"
                      :src="orderDetail.order_item.product.primary_image"
                      :alt="
                        currLanguage == 'en'
                          ? product_name_en ||
                            orderDetail.order_item.product.name
                          : currLanguage == 'vi'
                          ? orderDetail.order_item.product.name
                          : 'Image'
                      "
                      fluid
                    />
                  </div>
                </b-col>
                <b-col cols="12" :md="8" class="flex flex-col gap-2 px-2">
                  <div class="flex items-center justify-between">
                    <h5
                      style="width: 100%"
                      class="d-flex justify-content-between gap-1 mb-0"
                    >
                      <span v-if="orderDetail.order_item" class="mr-50">
                        {{
                          currLanguage == "en"
                            ? product_name_en ||
                              orderDetail.order_item.product.name
                            : orderDetail.order_item.product.name
                        }}
                      </span>
                      <div
                        :set="(statusInfo = getOrderItemStatus(orderDetail))"
                      >
                        <b-badge
                          v-if="statusInfo"
                          class="cs-badge"
                          :variant="statusInfo.variant"
                          >{{ statusInfo.label }}</b-badge
                        >
                      </div>
                    </h5>
                    <!-- <span v-if="orderDetail.order_item">X {{ orderDetail.order_item.qty}}</span> -->
                  </div>

                  <div
                    v-if="
                      orderDetail.status === ORDER_FORM_PROCESSOR_STATUS.PENDING
                    "
                    class="flex gap-2 mt-2"
                    style="height: 40px"
                  >
                    <b-button
                      @click="
                        changeStep(ORDER_FORM_PROCESSOR_STATUS.PROCESSING)
                      "
                      class="mr-1"
                      variant="outline-primary"
                      >{{ $t("lbl_accept") }}</b-button
                    >
                    <b-button
                      @click="changeStep(ORDER_FORM_PROCESSOR_STATUS.CANCEL)"
                      variant="outline-danger"
                      >{{ $t("msg_reject") }}</b-button
                    >
                  </div>

                  <div
                    class="dropdown mt-1"
                    v-if="
                      orderDetail.status ===
                        ORDER_FORM_PROCESSOR_STATUS.PROCESSING &&
                      orderDetail.return_date
                    "
                  >
                    <p class="mb-0">
                      {{ $t("lbl_processor.lbl_return_date") }}:
                      {{ formatDate(orderDetail.return_date) }}
                      <svg
                        @click="toggleReturnDate"
                        class="cursor-pointer"
                        v-b-tooltip.hover
                        :title="$t('lbl_update')"
                        width="16"
                        height="16"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5 17.9999H9.24C9.37161 18.0007 9.50207 17.9755 9.62391 17.9257C9.74574 17.8759 9.85656 17.8026 9.95 17.7099L16.87 10.7799L19.71 7.99994C19.8037 7.90698 19.8781 7.79637 19.9289 7.67452C19.9797 7.55266 20.0058 7.42195 20.0058 7.28994C20.0058 7.15793 19.9797 7.02722 19.9289 6.90536C19.8781 6.7835 19.8037 6.6729 19.71 6.57994L15.47 2.28994C15.377 2.19621 15.2664 2.12182 15.1446 2.07105C15.0227 2.02028 14.892 1.99414 14.76 1.99414C14.628 1.99414 14.4973 2.02028 14.3754 2.07105C14.2536 2.12182 14.143 2.19621 14.05 2.28994L11.23 5.11994L4.29 12.0499C4.19732 12.1434 4.12399 12.2542 4.07423 12.376C4.02446 12.4979 3.99924 12.6283 4 12.7599V16.9999C4 17.2652 4.10536 17.5195 4.29289 17.707C4.48043 17.8946 4.73478 17.9999 5 17.9999ZM14.76 4.40994L17.59 7.23994L16.17 8.65994L13.34 5.82994L14.76 4.40994ZM6 13.1699L11.93 7.23994L14.76 10.0699L8.83 15.9999H6V13.1699ZM21 19.9999H3C2.73478 19.9999 2.48043 20.1053 2.29289 20.2928C2.10536 20.4804 2 20.7347 2 20.9999C2 21.2652 2.10536 21.5195 2.29289 21.707C2.48043 21.8946 2.73478 21.9999 3 21.9999H21C21.2652 21.9999 21.5196 21.8946 21.7071 21.707C21.8946 21.5195 22 21.2652 22 20.9999C22 20.7347 21.8946 20.4804 21.7071 20.2928C21.5196 20.1053 21.2652 19.9999 21 19.9999Z"
                          fill="#20419b"
                        />
                      </svg>
                    </p>
                  </div>
                  <div
                    class="dropdown mt-1"
                    v-if="
                      orderDetail.status === ORDER_FORM_PROCESSOR_STATUS.CANCEL
                    "
                  >
                    <p>
                      {{ $t("lbl_processor.lbl_reject_reason") }}:
                      {{ orderDetail.reason }}
                    </p>
                  </div>
                  <div class="dropdown mt-1" v-if="isRejecting">
                    <b-form-textarea
                      class="mb-1"
                      v-model="rejectReason"
                      :placeholder="$t('lbl_enter_reason')"
                      rows="2"
                    ></b-form-textarea>
                    <b-button @click="handleRejected()" variant="primary">
                      {{ $t("lbl_update") }}
                    </b-button>
                  </div>
                  <div class="dropdown mt-1" v-if="isReceiving">
                    <label for="example-datepicker">
                      {{ $t("lbl_processor.lbl_choose_a_return_date") }}:
                    </label>
                    <input
                      id="example-datepicker"
                      class="cs-datetime-picker"
                      type="datetime-local"
                      :min="new Date()"
                      v-model="result_received_date"
                      :placeholder="
                        $t('lbl_processor.lbl_choose_a_return_date')
                      "
                      :locale="$i18n.locale || 'vi'"
                      v-bind="$getCalendarLabel($i18n.locale)"
                    />
                    <!-- <b-form-datepicker
                      id="example-datepicker"
                      v-model="result_received_date"
                      value-as-date
                      :min="new Date()"
                      :placeholder="
                        $t('lbl_processor.lbl_choose_a_return_date')
                      "
                      close-button
                      :locale="$i18n.locale"
                      v-bind="$getCalendarLabel($i18n.locale)"
                    ></b-form-datepicker> -->
                    <b-button
                      @click="updateReturnDate()"
                      variant="primary"
                      class="mt-1"
                      >{{ $t("lbl_update") }}</b-button
                    >
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import appUtils from "@/utils/appUtils";
import { ORDER_FORM_PROCESSOR_STATUS } from "@/utils/constant";

export default {
  name: "OrderInfomation",
  data() {
    return {
      loading: false,
      appUtils,
      isReceiving: false,
      isRejecting: false,
      rejectReason: "",
      result_received_date: null,
      ORDER_FORM_PROCESSOR_STATUS,
      currLanguage: this.$store.state.language.currLanguage,
    };
  },
  props: {
    orderDetail: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    options() {
      return [
        {
          label: this.$t("lbl_sample_recevied"),
          value: 1,
        },
        {
          label: this.$t("lbl_order_processor_process"),
          value: 2,
        },
        {
          label: this.$t("lbl_submit_results"),
          value: 3,
        },
        {
          label: this.$t("lbl_sample_did_not_yield_results"),
          value: 4,
        },
      ];
    },
    product_name_en() {
      return this.orderDetail.order_item.product.product_multilanguages?.find(
        (elm) => elm.language == "en"
      )?.name;
    },
  },
  created() {
    this.isReceiving =
      !this.orderDetail.return_date &&
      [
        ORDER_FORM_PROCESSOR_STATUS.PENDING,
        ORDER_FORM_PROCESSOR_STATUS.PROCESSING,
      ].includes(this.orderDetail.status);
  },
  methods: {
    changeStep(status) {
      if (!this.orderDetail?.id) return;

      if (status === ORDER_FORM_PROCESSOR_STATUS.CANCEL) {
        this.isRejecting = true;
        this.isReceiving = false;
      } else {
        this.isRejecting = false;
        this.$emit("changeStatus", {
          id: this.orderDetail.id,
          status: ORDER_FORM_PROCESSOR_STATUS.PROCESSING,
        });
      }
    },
    updateReturnDate() {
      if (this.result_received_date) {
        this.$emit("changeStatus", {
          id: this.orderDetail.id,
          status: this.orderDetail.status,
          result_received_date: moment(this.result_received_date).valueOf(),
        });
        this.isReceiving = false;
      } else {
        this.$bvModal.msgBoxOk(this.$t("lbl_processor.lbl_return_date_msg"), {
          title: this.$t("lbl_result_date"),
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          headerClass: "p-2 border-bottom-0",
          footerClass: "pt-1 p-2 border-top-0",
          centered: true,
        });
      }
    },
    handleRejected() {
      this.$emit("changeStatus", {
        id: this.orderDetail.id,
        status: ORDER_FORM_PROCESSOR_STATUS.CANCEL,
        reason: this.rejectReason,
      });
      this.isRejecting = false;
    },
    toggleReturnDate() {
      this.isReceiving = true;
      this.result_received_date = new Date(this.orderDetail.return_date);
    },
    getOrderItemStatus(orderItem) {
      if (!orderItem?.status) return;

      switch (orderItem.status) {
        case ORDER_FORM_PROCESSOR_STATUS.PENDING:
          return {
            variant: "primary",
            label: this.$t("lbl_order_processor_pending"),
          };

        case ORDER_FORM_PROCESSOR_STATUS.PROCESSING:
          return {
            variant: "info",
            label: this.$t("lbl_order_processor_process"),
          };
        case ORDER_FORM_PROCESSOR_STATUS.COMPLETE:
          return {
            variant: "success",
            label: this.$t("lbl_order_processor_complete"),
          };
        case ORDER_FORM_PROCESSOR_STATUS.CANCEL:
          return {
            variant: "danger",
            label: this.$t("lbl_order_processor_cancel"),
          };
      }
    },
    formatDate(time) {
      const date = moment.utc(time).format();
      return moment.utc(date).local().format("DD/MM/YYYY");
    },
  },
  watch: {
    "$store.state.language.currLanguage": function () {
      this.currLanguage = this.$store.state.language.currLanguage;
    },
  },
};
</script>

<style lang="scss" scoped>
.attachment-product {
  background-color: #dee4f8;
  color: #20419b;
  border-radius: 15px;
  cursor: pointer;
  gap: 0.25rem !important;
  justify-content: center;
  align-items: center;
  width: fit-content;
  padding: 5px 15px;
}
.dropdown {
  width: 100%;
}

.cs-badge {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
}

.cs-datetime-picker {
  display: block;
  border: 1px solid #dedede;
  min-height: 40px;
  border-radius: 8px;
  width: 100%;
  padding: 4px 8px;
}
</style>>
