<template>
  <div class="wrapper d-flex">
    <div v-if="!!orderPersonInfo">
      <p class="title">{{ $t('lbl_service_customer') }}</p>
      <p>{{ orderPersonInfo.name }}</p>
      <!-- <p>{{ orderPersonInfo.phone }}</p> -->
      <p>{{ (orderPersonInfo.address1 ? `${orderPersonInfo.address1} - ` : "") + orderPersonInfo.address2 }}</p>
    </div>
    <div v-if="!!receiverInfo">
      <p class="title">{{ $t('lbl_result_recipient') }}</p>
      <p>{{ receiverInfo.name }}</p>
      <!-- <p>{{ receiverInfo.phone }}</p> -->
      <p>{{ (receiverInfo.address1 ? `${receiverInfo.address1} - ` : "") + receiverInfo.address2 }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    orderContact: {
      type: Array,
      default: () => {}
    }
  },
  computed: {
    orderPersonInfo() {
      return this.orderContact?.find(person => person.type == 1);
    },
    receiverInfo() {
      return this.orderContact?.find(person => person.type == 2);
    }
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  flex-direction: column;
  gap: 15px;
}
.title {
  font-size: 18px;
  font-weight: 600;
}
</style>